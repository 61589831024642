import React from "react";

const BreadCrumb = ({ children, className }) => {
  return (
    <span className={`text-[#3f777c] uppercase font-LatoRegular ${className}`}>
      {children}
    </span>
  );
};

export default BreadCrumb;
