import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import BreadCrumb from "../BreadCrumb";
import BgImage from "../../assets/images/product_category/bg.png";
import BgImageDesktop from "../../assets/images/product_category/bg_desktop.png";

const Backgrounds = () => (
  <>
    <div className="lg:hidden absolute top-[7rem] right-0">
      <img
        src={BgImage}
        alt="Mobile Background"
        className="esm:w-[20rem] w-[40rem]"
      />
    </div>
    <div className="md:hidden absolute top-[3rem] xl:top-[-5rem] 2xl:top-[-10rem] right-0">
      <img
        src={BgImageDesktop}
        alt="Desktop Background"
        className="w-[40rem] xl:w-[55rem] 2xl:w-[67rem]"
      />
    </div>
  </>
);

const Products = ({ product, link }) => {
  const { image, name } = product;
  return (
    <Link to={link}>
      <div className="mx-4 md:m-2 flex flex-col items-center ">
        <div className="m-4 lg:p-8 hover:bg-[#f4ebe1]">
          <GatsbyImage
            alt={name}
            image={getImage(image)}
            className="w-full"
            objectFit="contain"
          />
        </div>
        <h3 className="font-OswaldRegular text-custom-teal-head font-normal">
          {name}
        </h3>
      </div>
    </Link>
  );
};

const ProductCategory = () => {
  return (
    <div className="bg-heading-color">
      <div className="pt-16 lg:max-w-[84rem] m-auto">
        <Backgrounds />
        <BreadCrumb className="pl-8">Home/Products/Grid</BreadCrumb>
        <div className="flex pt-8 px-8 items-end">
          <StaticImage
            src="../../assets/images/product_category/grid.png"
            alt="Grid Product"
            className="w-[45px] h-[45px] mr-4"
          />
          <span className="uppercase text-custom-brown font-OswaldMedium text-5xl">
            Grid
          </span>
        </div>
        <div className="px-8 pt-12 flex md:flex-col lg:items-end">
          <StaticImage
            src="../../assets/images/home/product1.jpg"
            alt="Grid Product"
            className="w-full max-w-[40rem] md:m-auto"
          />
          <p className="text-custom-teal md:pt-12 lg:pl-12 lg:max-w-[24rem] lg:mb-0 text-[20px]">
            Combining the ease of installation afforded by interlocking grid systems
            with the manufactured accuracy and durability of press formed tiles, th
            Supersil tegular System provides an economic solutio ceiling
            requirements. The system creates a modular arrangement with the tiles
            laying into the chosen grid.
          </p>
        </div>
        <div className="grid md:grid-cols-2 px-8 grid-cols-3 m-auto pb-16 mt-16">
          <StaticQuery
            query={graphql`
              {
                allHomeJson {
                  nodes {
                    product_categories {
                      products {
                        image {
                          childImageSharp {
                            gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                          }
                        }
                        name
                      }
                    }
                  }
                }
              }
            `}
            render={({ allHomeJson: { nodes } }) => {
              const productCategories = nodes[0].product_categories[0].products;
              console.log("Product Categories", productCategories);
              return productCategories.map((val, i) => (
                <Products product={val} link="/products/first-product" />
              ));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductCategory;
