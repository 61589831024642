import React from "react";
import Seo from "../components/seo";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ProductCategory from "../components/Product_Category";

const Works = () => {
  return (
    <>
      <Seo title="Smiith and Mould: Products" />
      <Navbar />
      <ProductCategory />
      <Footer />
    </>
  );
};

export default Works;
